main {
    height: fit-content;
}

.main-container {
    display: flex;
    flex-direction: row;
    position: relative;
}

aside {
    position: absolute;
    right: -30px;
    top: 333px;
    align-self: center;
    width: 22.5rem;
}

figure {
    position: relative;
}

.logo-container>figure>img,
.section-container-3d>video {
    width: 100%;
}

figcaption {
    position: absolute;
    inset: 0;
    top: 30%;
    width: 30%;
}

#arasaka-welcome-title {
    letter-spacing: 3px;
}

.aside-aboutus {
    position: absolute;
    right: 30px;
    top: 190px;
}

.section-content {
    display: grid;
    grid-template-columns: 3fr 1fr;
    margin-top: 50px;
}

.section-container-3d {
    width: fit-content;
    margin: auto;
    height: 686px;
    overflow: hidden;
    position: relative;
}

.section-container-3d>div {
    height: 100%;
    width: 100%;
}

.section-container-3d>video {
    max-width: 800px;
    height: auto;
}

.overlay-black {
    position: absolute;
    z-index: 1;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 39%, rgba(0, 0, 0, 1) 100%);
}


@media screen and (max-width: 1280px) {

    .main-container {
        flex-direction: column;
    }

    aside {
        position: initial;
        margin-bottom: 50px;

    }


    .aside-aboutus {
        position: initial;
        margin: auto;
        width: fit-content;
        margin-top: 75px;
    }

}

@media screen and (max-width: 800px) {
    .section-content {
        display: flex;
        flex-direction: column;
    }

    .section-container-3d {
        max-height: 60vh;
    }

    .section-content>div+div {
        margin-bottom: 50px;
    }
}