.news-important-item {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: min(40px, 1vw);
    margin-bottom: 100px;
}

.news-item summary {
    display: flex;
    flex-direction: column;
}

.new-item>a>h2> {
    margin-bottom: 2em;
}

.news-editorial-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.news-small-banner-container {
    max-width: 370px;
    max-height: 200px;
    overflow: hidden;
}

.news-small-banner {
    /* object-fit: contain; */
    width: 370px;
    height: 200px;
    transition: transform .5s;
}

.news-small-banner:hover {
    -ms-transform: scale(1.5);
    /* IE 9 */
    -webkit-transform: scale(1.5);
    /* Safari 3-8 */
    transform: scale(1.5);

}

wbr .news-editorial-section>div {
    width: 31%;
}

.news-editorial-section>div>a {
    display: flex;
    flex-direction: column;
    height: 105%;
}

.news-editorial-section>div>a>footer {
    margin-top: auto;
    align-self: flex-end;
}

.mediacenter-container>* {
    width: 50%;
}

.news-full-image {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    margin-top: 100px;
    left: 50%;
    max-height: 490px;
    overflow: hidden;  
    opacity: 0.9;  
}

.news-full-image>img {
    width: 100%;
    height: auto;
}

.news-description-content {
    width: 60%;
    margin-left: auto;
}


@media screen and (max-width: 1280px) {

    .news-important-item {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .news-editorial-section {
        flex-direction: column;
        align-items: center !important;
    }

    .news-item>a>h2 {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 80vw;
    }
}