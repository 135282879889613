.avatar-container {
    border: 1px solid rgba(255, 255, 255, 0.7);
    width: 364px;
    height: 450px;
    aspect-ratio: 364/450;
    position: relative;
}

.avatar-image {
    width: auto;
    height: 500px;
    aspect-ratio: 364/503;
    position: absolute;
    top: -50px;
    left: 0;
}

.avatar-text {
    width: 100%;
}

.avatar-image.sniper {
    left: -36px !important;
}

.avatar-code {
    width: fit-content;
}

.avatar-layout-container {
    margin-top: 55px;
    width: 100%;
}


.avatar-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 50px;
    justify-items: center;
}



/* Responsiveness */


@media screen and (max-width: 1280px) {

    .avatar-grid {
        grid-template-columns: 1fr 1fr;
        gap: 25px;
    }

}

@media screen and (max-width: 800px) {
    .avatar-grid {
        display: flex;
        flex-direction: row;
        width: fit-content;
        flex-wrap: nowrap;
        margin: auto;
        height: 100%;
        align-items: flex-end;
        gap: 15px;
    }

    .scroll-x {
        overflow: auto;
    }

    .scroll-x::-webkit-scrollbar {
        display: none;
        scrollbar-width: none;
    }

    .avatar-layout-container {
        width: 100%;
        height: 575px;
        margin: 5px 0 15px 0;
    }

    .avatar-container,
    .avatar-container img {
        width: 340px;
        height: auto;
    }

}