.benefits-list>li {
    margin-right: 30px;
    padding: 10px 0 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.benefits-list>li::before {
    display: inline-flex;
    content: "";
    background-image: url(../../public/assets/svg/check.svg);
    width: 25px;
    height: 25px;
    background-size: 25px 25px;
    margin-right: 10px;
    filter: invert(9%) sepia(100%) saturate(5983%) hue-rotate(342deg) brightness(114%) contrast(115%);
    /* https: //codepen.io/sosuke/pen/Pjoqqp for filter colors */
}


.benefits-list>li+li {
    border-top: 1px solid white;
}

.job-list>li {
    border-top: 1px solid white;
    padding: 30px 0 30px 0;
}