.frame-container>div {
    padding: 40px;
}

.frame-container {
    position: relative;
    height: fit-content;
    font-size: 2em;
    word-wrap: none;
    text-wrap: nowrap;
    margin-bottom: 50px;
    text-align: center;
}

.frame-corner {
    position: absolute;
    width: 20px;
    opacity: 0.7;
    filter: invert(85%) sepia(58%) saturate(2%) hue-rotate(231deg) brightness(105%) contrast(101%);
}