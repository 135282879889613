
/* Google Fonts */
@import url(https://fonts.googleapis.com/css?family=Anonymous+Pro);


.line-1 {
  position: relative;
  top: 50%;
  font-size: 180%;
  margin: 0 auto;
  text-align: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.anim-typewriter {
    color: hsl(0, 0%, 90%);
  width: 0;
}

.loading-dir-text {
    color: hsl(0, 0%, 40%);
}

.loading-percent {
    color: rgb(42, 156, 42);
}

.typed-text {
  width: 20.18em;
  white-space: nowrap;
  overflow: hidden;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.typed-text.carpet {
  border-right: 2px solid rgba(255, 255, 255, .75);
}