:root {
  /* Arasaka color palette */
  --arasaka-black: #0D0D0D;
  --cyber-silver: #C0C0C0;
  --neon-red: #FF0033;
  --neon-red-light: #ff4b72;
  --corporate-grey: #525252;
  --futuristic-blue: #0050EF;
}

body {
  margin: 0;
  margin-top: -35px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: calc(100vh - 8em);
  color: rgba(255, 255, 255, .75);
  font-family: 'Anonymous Pro', monospace;
  background-color: black;
}

* {
  margin: 0;
  padding: 0;
  user-select: none;
  white-space: pre-line;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {}

a {
  color: var(--cyber-silver);
  text-decoration: none;
}



/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #070606;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0f0f0f;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #181818;
}

/* Scrollbar for Figures : */

figure::-webkit-scrollbar-track {
  background: #202020;
}

figure::-webkit-scrollbar-thumb {
  background: #424242;
}


/* Color palette : */

h1,
.title {
  color: var(--neon-red) !important;
}

a.title {
  text-underline-offset: 12px;
  text-decoration: underline;
}

a.title:hover {
  color: var(--neon-red-light) !important;
}

.title.secondary {
  opacity: 0.7;
}

/* Font Size : */
.very-very-large {
  font-size: 50px;
}

.very-large {
  font-size: 40px;
}

.large {
  font-size: 30px;
}

.small {
  font-size: 16px;
}

.no-width {
  width: 0;
}

/* Stairs : */
.stairs-disposition {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: fit-content;
}

.stairs-center {
  align-self: center;
}

.section-title {
  display: flex;
  flex-direction: row;
}

/* Sections : */
.section-container {
  display: flex;
  flex-direction: column;
}

.section-container>h1 {
  margin: 100px;
}

.section-container>p {
  margin-bottom: 100px;
}

/* Flex : */
.flex {
  display: flex;
  flex-flow: row wrap;
}

.col {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.flex.gap-1 {
  gap: 10px;
}

.end {
  justify-content: flex-end;
}

.split-2 {
  float: left;
  width: 50%;
}

.split-3 {
  float: left;
  width: 33%;
}

.split-1 {
  width: 100%;
}


.center {
  justify-content: center;
}

.t-center {
  text-align: center;
}

.space-between {
  justify-content: space-between;
}

/* Spacing */
.espace.top {
  margin-top: 50px;
}

.espace.bot {
  margin-bottom: 50px;
}

.espace.top-1 {
  margin-top: 10px;
}

.espace.top-3 {
  margin-top: 150px;
}

.espace.bot-1 {
  margin-bottom: 10px;
}

.espace.bot-3 {
  margin-bottom: 30px;
}

/* Overflow */
.no-over {
  overflow: hidden !important;
}


/* Grid : */
.grid {
  display: grid;
}

.grid.col-2 {
  grid-template-columns: 1fr 1fr;
}

.grid.gap-1 {
  gap: 100px;
}

/* Images : */
.fit-image {
  max-width: 100%;
  transition: transform .5s;
}

.image-container {
  overflow: hidden;
}

.figure-max {
  max-width: 1200px;
}

.scale:hover {
  -ms-transform: scale(1.5);
  /* IE 9 */
  -webkit-transform: scale(1.5);
  /* Safari 3-8 */
  transform: scale(1.5);
}

.scale-2:hover {
  -ms-transform: scale(2);
  /* IE 9 */
  -webkit-transform: scale(2);
  /* Safari 3-8 */
  transform: scale(2);
}

/* List : */
ul {
  list-style-type: none;
  padding: 0;
}

.double-list>li:nth-child(even) {
  margin-bottom: 15px;
}

/* Padding */

.pad-1 {
  padding: 10px;
}

.pad-5 {
  padding: 50px;
}

/* Radius : */
.radius-8 {
  border-radius: 8px;
}

/* Select  */

select {
  border-right: 15px solid var(--cyber-silver);
  outline: 1px solid var(--arasaka-black);
  background-color: var(--cyber-silver);
}

/* Flex item */

.item.centered {
  align-self: center;
}

.item.end {
  margin-left: auto;
}

/* Text  */
.underline {
  text-decoration: underline;
}

/* Custom Accordion */
.accordion-custom {
  width: fit-content;
  margin: auto;
  margin-top: 15px;
}

.accordion-custom>button {
  font-size: min(calc(1rem + ((1vw - 7.68px) * 0.6944)), 20px);
  justify-content: center !important;
  width: min(330px, 40vw);
}

.accordion-custom>button>span {
  display: none;
}

.accordion-hr-none+hr {
  display: none;
}

/* Responsiveness */

@media screen and (max-width: 740px) {

  .mobile-hidden {
    display: none !important;
  }

}

@media screen and (max-width: 1280px) {

  .tablet-hidden {
    display: none !important;
  }

}