header {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 10px;
    z-index: 100;
}

header>* {
    width: fit-content;
    height: fit-content;
    padding: 0;
}

.header-nav {
    list-style-type: none;
    font-size: 19px;
}

.header-nav>ul>li>a {
    color: rgba(255, 255, 255, .75);
    text-decoration: none;
}

.header-nav>ul>li>a:hover {
    color: rgba(255, 255, 255, 0.90);
    text-decoration: underline;
}

.header-nav.secondary {
    font-size: 17px;
}

.header-nav>ul>li {
    display: inline-block;
    margin-left: 2em;
}

.header-logo {
    max-width: 300px;
    max-height: 100px;
}

.header-nav-container {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    row-gap: 12px;
}

.bourse-container {
    border-radius: 35%;
    font-size: 14px;
}

.bourse-name {
    color: rgba(255, 255, 255, 0.7);
}

.bourse-fluctuation {
    color: rgba(82, 172, 68, 0.7);
}

.mobile-menu {
    display: none;
}

@media screen and (max-width: 1280px) {
    .header-nav-container {
        display: none;
    }

    .header-nav-container.active {
        display: flex;
        touch-action: none;
    }

    .header-logo {
        max-width: clamp(30%, 50%, 300px);
    }

    .mobile-menu {
        display: block;
        position: relative;
        z-index: 1;
        color: var(--neon-red);
    }

    .header-nav-container {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: rgba(82, 82, 82, 0.7);
        width: 400px;
        height: 100%;
        position: fixed;
        top: 0rem;
        left: auto;
        right: 0rem;
        bottom: 0rem;
        padding-top: 150px;
    }

    .header-nav>ul {
        display: flex;
        flex-direction: column;
        font-size: 30px;
        gap: 50px;
    }

    .header-nav-container>hr {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}

.blurred-background {
    backdrop-filter: blur(3px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    overflow: hidden;
    z-index: -1;
    height: 60px;
}

@media screen and (max-width: 740px) {

    .header-nav-container {
        width: 100%;
    }

}