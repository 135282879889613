@media all and (min-width: 1024px) and (max-width: 1280px) {}

@media all and (max-width: 1024px) {
    .split-2 {
        width: 100% !important;
    }

    .split-3 {
        width: 50% !important;
    }

    .news-description-content {
        width: 100% !important;
    }
}

@media all and (min-width: 480px) and (max-width: 768px) {}

@media all and (max-width: 480px) {}