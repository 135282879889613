.layout {
    margin: 0 auto;
    max-width: 1200px;
    padding: 10px;
}

/* @media (max-width: 768px) {
    .layout {
        padding: 0 10px;
    }
} */